.modal {
  position: fixed;
  overflow: hidden;
  width: 0;
  height: 0;
  border-radius: 25px;
  background: #190039;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.5s ease, height 0.5s ease;
}

.modal-show {
  width: 85%;
  height: 80%;
}

.modal-close {
  color: #fff;
  position: absolute;
  top: 3%;
  right: 2%;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  font-family: Cagliostro;
  font-size: 30px;
  z-index: 3;
}

.modal-close:hover {
  background: #fff;
  color: rgb(128, 128, 128);
  cursor: pointer;
}

.modal-banner {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 25px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.modal-banner-fade {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  top: 0;
  height: 101%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #190039 99%);
}

.modal-tagline-container {
  position: absolute;
  top: 30%;
  left: 6%;
  z-index: 2;
}

.modal-hi-there {
  color: #ffdc00;
  font-family: Anton;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 83px;
  line-height: 1;
  text-transform: uppercase;
}

.modal-welcome {
  color: #ffdc00;
  font-family: Anton;
  text-transform: uppercase;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 21px;
  line-height: 1;
}

.modal-play-video {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 10px;
  background: rgba(255, 251, 251, 0.3);
  color: #fff;
  width: 210px;
  height: 55px;
  font-family: Cairo;
  font-size: 24px;
  font-weight: 800;
  margin-top: 1rem;
}

.modal-play-video:hover {
  background: #fff;
  cursor: pointer;
  color: #8a6363;
}

.modal-rating-container {
  display: flex;
  flex-direction: row;
  color: #fff;
  width: 150%;
  margin-top: 1.5rem;
  align-items: center;
}

.modal-imdb {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Cairo;
  border: 1px solid #fff;
  border-radius: 3px;
  background: #000;
  height: 23px;
  width: 53px;
  font-size: 20px;
}

.modal-imdb-rating {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Baloo 2";
  height: 25px;
  font-size: 25px;
  margin-left: 15px;
}

.modal-rt {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Cairo;
  border: 1px solid #fff;
  border-radius: 3px;
  background: #000;
  height: 23px;
  width: 159px;
  font-size: 20px;
  margin-left: 30px;
}

.modal-rt-rating {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Baloo 2";
  height: 25px;
  font-size: 25px;
  margin-left: 15px;
}

.modal-tvma {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Cairo;
  border: 1px solid #fff;
  border-radius: 3px;
  background: #000;
  height: 23px;
  width: 68px;
  font-size: 20px;
  margin-left: 30px;
}

.modal-content-container {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 65%;
  left: 6%;
  right: 6%;
  color: white;
  z-index: 2;
}

.modal-content-left {
  width: 56%;
  text-align: justify;
  font-family: "Baloo 2";
  font-size: 25px;
  overflow: auto;
  height: 25vh;
}

.modal-content-right {
  width: 36%;
  text-align: left;
  font-family: "Baloo 2";
  font-size: 20px;
}

/* .modal-content-right-genres {
  color: #fedb00;
} */

.modal-content-delineator {
  font-weight: bold;
  color: #ffdc00;
}

@media (max-height: 840px) {
  .modal-content-container {
    top: 68%;
  }
  .modal-content-left {
    font-size: 20px;
  }
  .modal-content-right {
    font-size: 17px;
  }
}

@media (max-width: 1024px) {
  .modal-imdb {
    font-size: 18px;
    height: 18px;
    width: 45px;
  }
  .modal-imdb-rating {
    font-size: 22px;
  }
  .modal-rt {
    font-size: 18px;
    width: 137px;
    height: 18px;
  }
  .modal-rt-rating {
    font-size: 22px;
  }
  .modal-tvma {
    font-size: 18px;
    width: 57px;
    height: 18px;
  }
  .modal-hi-there {
    font-size: 70px;
  }
  .modal-welcome {
    font-size: 18px;
  }
  .modal-play-video {
    width: 180px;
    height: 50px;
    font-size: 22px;
  }
  .modal-content-left {
    font-size: 18px;
  }
  .modal-content-right {
    font-size: 17px;
  }
}

@media (max-width: 900px) {
  .modal-imdb {
    font-size: 16px;
    height: 16px;
    width: 40px;
  }
  .modal-imdb-rating {
    font-size: 20px;
  }
  .modal-rt {
    font-size: 16px;
    width: 121px;
    height: 16px;
  }
  .modal-rt-rating {
    font-size: 20px;
  }
  .modal-tvma {
    font-size: 16px;
    width: 49px;
    height: 16px;
  }
  .modal-hi-there {
    font-size: 60px;
  }
  .modal-welcome {
    font-size: 15.3px;
  }
  .modal-play-video {
    width: 160px;
    height: 40px;
    font-size: 20px;
  }

  .modal-content-left {
    font-size: 17px;
  }
  .modal-content-right {
    font-size: 17px;
  }
}

@media (max-width: 800px) {
  .modal-content-container {
    top: 60%;
  }
}
@media (max-width: 700px) {
  .modal-show {
    width: 90%;
    height: 70%;
  }
  .modal-tagline-container {
    top: 20%;
  }
  .modal-content-left {
    font-size: 16px;
  }
  .modal-content-right {
    font-size: 16px;
  }
}
@media (max-width: 600px) {
  .modal-show {
    width: 95%;
    height: 65%;
  }
  .modal-content-left {
    font-size: 15px;
  }
  .modal-content-right {
    font-size: 15px;
  }
}

@media (max-width: 500px) {
  .modal-imdb {
    font-size: 12px;
    height: 12px;
    width: 30px;
  }
  .modal-imdb-rating {
    margin-left: 10px;
    font-size: 16px;
  }
  .modal-rt {
    margin-left: 15px;
    font-size: 12px;
    width: 92px;
    height: 12px;
  }
  .modal-rt-rating {
    margin-left: 10px;
    font-size: 16px;
  }
  .modal-tvma {
    margin-left: 15px;
    font-size: 12px;
    width: 38px;
    height: 12px;
  }
  .modal-hi-there {
    font-size: 40px;
  }
  .modal-welcome {
    font-size: 10.2px;
  }
  .modal-play-video {
    width: 120px;
    height: 30px;
    font-size: 16px;
  }
  .modal-show {
    width: 95%;
    height: 65%;
  }
  .modal-content-left {
    font-size: 14px;
  }
  .modal-content-right {
    font-size: 13px;
  }
  .modal-content-container {
    top: 55%;
  }
}

@media (max-width: 400px) {
  .modal-show {
    width: 95%;
    height: 60%;
  }
  .modal-tagline-container {
    top: 10%;
  }
  .modal-content-left {
    font-size: 13px;
  }
  .modal-content-right {
    font-size: 12px;
  }
  .modal-content-container {
    top: 50%;
  }
}
