.contact {
  height: 100vh;
  color: #fff;
  display: flex;
  flex-direction: row;
  font-family: "Baloo 2";
  position: relative;
}

.contact-section-heading {
  font-family: Cairo;
  font-size: 22px;
}

.contact-bottom {
  width: 90%;
  position: relative;
  top: 30vh;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.contact-left {
  width: 50%;
}

.contact-left-top,
.contact-right-top {
  height: 15vh;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}

.contact-left-middle,
.contact-right-middle {
  height: 15vh;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}

.contact-left-bottom,
.contact-right-bottom {
  height: 20vh;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}

.contact-right {
  width: 50%;
}

.contact-input-name,
.contact-input-email {
  font-family: "Baloo 2";
  width: 30rem;
  padding: 10px;
}

.contact-input-message {
  font-family: "Baloo 2";
  width: 30rem;
  height: 5rem;
  padding: 10px;
  resize: none;
}

.contact-top {
  position: absolute;
  top: 15vh;
  text-align: center;
  width: 100%;
}

.contact-heading {
  font-family: "Anton";
  font-size: 40px;
}

.contact-blurb {
  font-family: "Baloo 2";
  font-size: 18px;
}

.contact-submit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: rgba(255, 251, 251, 0.3);
  color: #fff;
  width: 210px;
  height: 55px;
  font-family: Cairo;
  font-size: 24px;
  font-weight: 800;
  box-shadow: 2px 2px 10px 2px black;
}

.contact-submit:hover {
  background: #fff;
  cursor: pointer;
  color: #8a6363;
}

@media (max-width: 1100px) {
  .contact-input-name,
  .contact-input-email,
  .contact-input-message {
    width: 95%;
  }
}

@media (max-width: 740px) {
  .contact-bottom {
    width: 95%;
  }
}

@media (max-width: 580px) {
  .contact-bottom {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .contact-left,
  .contact-right {
    width: 90%;
  }
  .contact-submit {
    margin: 0 auto;
  }
  .contact-left-top,
  .contact-left-middle {
    height: 10vh;
  }
  .contact-left-bottom {
    height: 15vh;
  }
}
