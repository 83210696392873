.nav {
  position: fixed;
  display: flex;
  flex-direction: column;

  width: 100%;
  z-index: 3;
  background: #1f0047;
}

.nav-items {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80px;
  align-items: center;
  justify-content: space-around;
}

.nav-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff;
  font-size: 18px;
  font-family: Cairo;
  text-decoration: none;
  font-weight: 800;
}

.nav-item svg {
  cursor: pointer;
  margin-left: 10px;
}

.nav-item:hover {
  color: #ffdc00;
  cursor: pointer;
}

.nav-item-selected {
  color: #ffdc00;
}

.nav-logo {
  display: flex;
  width: 57px;
  height: 57px;
  z-index: 6;
}
.nav-logo-mobile {
  margin-left: 20px;
}

.projects-caret-selected {
  transform: rotateZ(-180deg);
}
.projects-caret {
  transition: transform 0.5s ease;
}

.nav-projects {
  width: 100%;
  max-height: 0;
  height: 175px;
  transition: max-height 0.5s linear, padding-top 0.5s linear, top 0.5s linear;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  /* align-items: center; */
  overflow: hidden;
  background: #190039;
  padding-top: 0;
}

.nav-projects-show {
  padding-top: 20px;
  max-height: 500px;
  transition: max-height 0.5s linear, padding-top 0.5s linear;
}

.nav-projects-mobile {
  position: absolute;
  height: fit-content;
  padding-top: 5rem;
}

.nav-projects-container {
  width: 20%;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
}

.nav-projects-heading {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 20px;
  font-family: Cairo;
}

.nav-projects-item {
  font-family: "Baloo 2";
  font-size: 15px;
}

.nav-input {
  width: 0px;
  height: 34px;
  background-color: unset;
  box-shadow: none;
  color: #fff;
  border: 1px solid transparent;
  outline: none;
  padding-left: 35px;
  font-size: 16px;
  transition: 0.3s ease all;
}

.nav-input-mobile {
  padding-left: 25px;
}

.nav-input-show {
  width: 240px;
  border-color: #fff;
  padding-left: 35px;
}

.nav-item-search-icon {
  position: absolute;
  left: 60%;
  transition: left 0.3s ease;
}

.nav-item-search-icon-mobile {
  left: 67%;
}

.nav-item-search {
  right: 15px;
  top: 30px;
  position: absolute;
  display: inline-block;
}

.nav-item-search-text {
  width: 0;
  transition: width 0.3s ease, opacity 0.3s ease;
  opacity: 0;
}

.nav-item-search-text-show {
  width: 55px;
  opacity: 1;
}

.nav-mobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 5rem;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 5;
  background: #1f0047;
}

.nav-items-mobile {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 65px;
  right: 40px;
  align-items: flex-end;
}

.nav-content {
  width: 100%;
  height: 0;
  background: #1f0047;
  transition: height 1s ease;
  overflow: hidden;
  position: absolute;
  z-index: 1;
}

.nav-content-show {
  height: 255px;
}
.hamburger-btn {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  width: 40px;
  height: 24px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 20;
  cursor: pointer;
}

.hamburger-btn .line {
  display: block;
  background: white;
  width: 30px;
  height: 3px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  border-radius: 50px;
  transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

.hamburger-btn .line.line-1 {
  top: 0;
}

.hamburger-btn .line.line-2 {
  top: 50%;
  margin-top: -2px;
  width: 19px;
  right: 26%;
  left: auto;
}

.hamburger-btn .line.line-3 {
  top: 20px;
}

.hamburger-btn:hover .line-1,
.hamburger-btn:focus .line-1 {
  -ms-transform: translateY(-4px);
  -moz-transform: translateY(-4px);
  transform: translateY(-4px);
}

.hamburger-btn:hover .line-3,
.hamburger-btn:focus .line-3 {
  -ms-transform: translateY(4px);
  -moz-transform: translateY(4px);
  transform: translateY(4px);
}

.hamburger-btn.active .line {
  background: #ffdc00;
}

.hamburger-btn.active .line-1 {
  -ms-transform: translateY(25px) translateX(0) rotate(45deg);
  transform: translateY(25px) translateX(0) rotate(45deg);
  -webkit-transform: translateY(10px) translateX(0) rotate(45deg);
  -moz-transform: translateY(10px) translateX(0) rotate(45deg);
}

.hamburger-btn.active .line-2 {
  opacity: 0;
}

.hamburger-btn.active .line-3 {
  -ms-transform: translateY(-25px) translateX(0) rotate(-45deg);
  transform: translateY(-25px) translateX(0) rotate(-45deg);
  -webkit-transform: translateY(-10px) translateX(0) rotate(-45deg);
  -moz-transform: translateY(-10px) translateX(0) rotate(-45deg);
}
