.slick-dots {
  bottom: 40% !important;
}

.slick-dots li button:before {
  font-size: 16px;
  opacity: 0.6;
  color: white;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: white;
}

.slick-prev {
  left: 0;
  z-index: 1;
  height: 100%;
  width: 5%;
}

.slick-next {
  right: 0;
  z-index: 1;
  height: 100%;
  width: 5%;
}

.slick-prev:hover,
.slick-next:hover {
  background: rgba(255, 251, 251, 0.3);
}

.slick-next:before,
.slick-prev:before {
  font-size: 30px;
}

.home-row-content > .slick-slider > .slick-list {
  overflow: visible;
  height: 13rem;
}

.home-row-content > .slick-slider > .slick-list > .slick-track > .slick-slide {
  width: unset !important;
  margin: 0 0.5rem;
}

.home-carousel {
  position: relative;
  margin-top: 5rem;
}
.home-carousel::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%; /* Adjust the percentage to control the fade height */
  background: linear-gradient(
    180deg,
    rgba(23, 0, 52, 0) 0%,
    rgba(12, 12, 12, 0) 33.33%,
    rgba(0, 0, 0, 0.5) 66.67%,
    #190038 100%
  );
  pointer-events: none; /* Allows clicks to pass through to the image */
}
.home-carousel-img {
  position: relative;
  width: 100%;
  height: auto;
}

.home-learn-more-wrapper {
  position: absolute;
  top: 40%;
  left: 6%;
  z-index: 1;
}
.home-shamanta {
  color: #ffdc00;
  font-family: Anton;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 51px;
  line-height: 1;
}
.home-haider {
  color: #ffdc00;
  font-family: Anton;
  text-transform: uppercase;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 80px;
  line-height: 1;
}
.home-learn-more {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: rgba(255, 251, 251, 0.3);
  color: #fff;
  width: 210px;
  height: 55px;
  font-family: Cairo;
  font-size: 24px;
  font-weight: 800;
}

.home-learn-more:hover {
  background: #fff;
  cursor: pointer;
  color: #8a6363;
}

.home-learn-more svg {
  margin-left: 10px;
}

.home-row-container {
  position: relative;
  margin: 0 1% 2% 1%;
}

.whats-new-container {
  margin-top: -22%;
  z-index: 1;
}

.theatre-container {
  padding-bottom: 10%;
}

.home-row-text {
  margin-left: 2%;
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Cairo;
  font-size: 35px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: capitalize;
}

.home-row-carousel-img {
  width: 20rem;
  height: 13rem;
  border-radius: 10px;
  transition: transform 0.3s ease;
  transform-origin: center center;
}

.home-row-carousel-img:hover {
  transform: scale(1.3);
  cursor: pointer;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.home-row-content-pr-marketing {
  display: flex;
  flex-direction: row;
}
.home-row-content-pr-marketing > div > img {
  margin-right: 1rem;
}

@media (max-width: 820px) {
  .home-learn-more-wrapper {
    top: 30%;
  }
  .whats-new-container {
    margin-top: -20%;
  }
}

@media (max-width: 720px) {
  .whats-new-container {
    margin-top: -15%;
  }
}

@media (max-width: 650px) {
  .home-shamanta {
    font-size: 42px;
  }
  .home-haider {
    font-size: 65px;
  }
  .home-learn-more {
    width: 170px;
    height: 48px;
    font-size: 20px;
  }
}

@media (max-width: 550px) {
  .slick-dots {
    bottom: 20% !important;
  }
}

@media (max-width: 460px) {
  .home-learn-more-wrapper {
    top: 20%;
  }
}

@media (max-width: 400px) {
  .home-shamanta {
    font-size: 32px;
  }
  .home-haider {
    font-size: 50px;
  }
  .home-learn-more {
    width: 130px;
    height: 32px;
    font-size: 16px;
  }
}
