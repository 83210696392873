.content-page {
  position: fixed;
  overflow: hidden;
  width: 0;
  height: 0;
  border-radius: 25px;
  background: #190039;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.5s ease, height 0.5s ease;
}

.content-page-show {
  width: 85%;
  height: 80%;
}

.content-page-image {
  position: fixed;
  overflow: hidden;
  display: inline-block;
  justify-content: center;
  border-radius: 25px;
  background: #190039;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: max-width 0.5s ease, max-height 0.5s ease;
  max-width: 0;
  max-height: 0;
}

.content-page-image-show {
  max-width: 90%;
  max-height: 90%;
}

.content-page-image-close {
  color: #fff;
  position: fixed;
  top: 3%;
  right: 2%;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  font-family: Cagliostro;
  font-size: 30px;
  z-index: 5;
}

.content-page-image-close:hover {
  background: #fff;
  color: rgb(128, 128, 128);
  cursor: pointer;
}

.content-page-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: overlay;
  overflow-x: hidden;
}

.content-page-close {
  color: #fff;
  position: fixed;
  top: 3%;
  right: 2%;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  font-family: Cagliostro;
  font-size: 30px;
  z-index: 3;
}

.content-page-close:hover {
  background: #fff;
  color: rgb(128, 128, 128);
  cursor: pointer;
}

.content-page-banner {
  position: relative;
  width: 100%;
  height: 100%;

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.content-page-banner-fade {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  top: 0;
  height: 101%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #190039 99%);
}

.content-page-tagline-container {
  position: relative;
  margin-top: -60vh;
  left: 6%;
  z-index: 2;
}

.content-page-tagline-top,
.content-page-tagline-bottom {
  color: #ffdc00;
  font-family: Anton;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: fit-content;
  line-height: 1;
}
.content-page-learn-more {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 10px;
  background: rgba(255, 251, 251, 0.3);
  color: #fff;
  width: 210px;
  height: 55px;
  font-family: Cairo;
  font-size: 24px;
  font-weight: 800;
  margin-top: 1rem;
}

.content-page-learn-more:hover {
  background: #fff;
  cursor: pointer;
  color: #8a6363;
}

.content-page-rating-container {
  display: flex;
  flex-direction: row;
  color: #fff;
  width: 150%;
  margin-top: 1.5rem;
  align-items: center;
}

.content-page-imdb,
.content-page-rt,
.content-page-tvma {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Cairo;
  border: 1px solid #fff;
  border-radius: 3px;
  background: #000;
  height: 23px;
  width: 53px;
  font-size: 20px;
}

.content-page-rt {
  width: 159px;
  margin-left: 20px;
}

.content-page-tvma {
  width: 68px;
  margin-left: 20px;
}

/* .content-page-imdb-rating,
.content-page-rt-rating,
.content-page-year {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Baloo 2";
  height: 25px;
  font-size: 25px;
  margin-left: 15px;
} */

.content-page-year {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Cairo;
  font-size: 30px;
  font-weight: 800;
  margin-top: 1rem;
}

.content-page-section {
  position: relative;
  color: white;
  z-index: 2;
  left: 6%;
  width: 88%;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.content-page-description {
  width: 45%;
  text-align: left;
  font-size: 26px;
  font-family: "Baloo 2";
  font-weight: 400;
  max-height: 20rem;
  overflow: auto;
  padding-right: 2%;
}

.content-page-responsibilities {
  font-weight: bold;
  margin-bottom: 10px;
}

.content-page-details {
  width: 45%;
  text-align: left;
  font-size: 18px;
  font-family: "Baloo 2";
  font-weight: 400;
  max-height: 20rem;
  overflow: auto;
  padding-right: 2%;
}

.content-page-details-text {
  margin-bottom: 1.2rem;
}

.content-page-content {
  position: relative;
  color: white;
  z-index: 2;
  left: 6%;
  width: 88%;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
}

.content-page-content-title {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Cairo;
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 1.5rem;
}

.content-page-content-media {
  width: 300px;
  height: 200px;
  margin-right: 15px;
  border-radius: 10px;
  object-fit: cover;
  transition: transform 0.3s ease;
  transform-origin: center center;
}

.content-page-content-media:hover {
  transform: scale(1.3);
  cursor: pointer;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.content-page-delineator {
  font-weight: bold;
  color: #ffdc00;
}

@-moz-document url-prefix() {
  .content-page-show {
    overflow-y: auto;
  }
}

@media (max-width: 1024px) {
  .content-page-year {
    font-size: 26px;
  }
  .content-page-description {
    font-size: 20px;
  }
  .content-page-content-title {
    font-size: 24px;
  }
  .content-page-tagline-container {
    margin-top: -50vh;
  }
}

@media (max-width: 800px) {
  .content-page-show {
    height: 70%;
  }
  .content-page-tagline-container {
    margin-top: -40vh;
  }
  .content-page-learn-more {
    width: 180px;
    height: 50px;
    font-size: 20px;
  }
}

@media (max-width: 700px) {
  .content-page-show {
    height: 65%;
  }
  .content-page-tagline-container {
    margin-top: -35vh;
  }
  .content-page-learn-more {
    width: 155px;
    height: 45px;
    font-size: 18px;
  }
}

@media (max-width: 600px) {
  .content-page-show {
    height: 60%;
  }
  .content-page-tagline-container {
    margin-top: -30vh;
  }
  .content-page-year {
    font-size: 20px;
  }
  .content-page-description {
    font-size: 16px;
  }
  .content-page-content-title {
    font-size: 18px;
  }
  .content-page-details {
    font-size: 16px;
  }
  .content-page-learn-more {
    width: 130px;
    height: 40px;
    font-size: 16px;
  }
}

@media (max-width: 500px) {
  .content-page-show {
    width: 90%;
    height: 50%;
  }
  .content-page-tagline-container {
    margin-top: -20vh;
  }
  .content-page-learn-more {
    width: 115px;
    height: 35px;
    font-size: 14px;
  }
}

@media (max-width: 400px) {
  .content-page-show {
    width: 95%;
    height: 40%;
  }

  .content-page-learn-more {
    width: 100px;
    height: 30px;
    font-size: 12px;
  }
}
