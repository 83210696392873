.search {
  margin-top: 7vh;
  height: 93vh;
  overflow-y: auto;
}

.search-text {
  margin-left: 2%;
  color: #fff;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  font-family: Cairo;
  font-size: 35px;
  font-weight: 800;
}

.search-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 96%;
  margin: auto;
}

.search-image-container {
  margin: 1rem 1rem 0 0;
}
