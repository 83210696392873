@import "./styles/nav.css";
@import "./styles//home.css";
@import "./styles/modal.css";
@import "./styles/contact.css";
@import "./styles/contentPage.css";
@import "./styles/search.css";
.App {
  background: #1f0047;
  background: linear-gradient(180deg, #13002c, #200047);
  overflow-x: hidden;
}

.hide-scroll-bar {
  overflow: hidden;
}

body {
  width: 100%; /* Set the width as needed */
  max-height: 300px; /* Set the maximum height to enable scrolling */
  overflow: overlay; /* Overlay scrollbar */
}

/* Style the scrollbar track */
::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
  background-color: transparent;
}

/* Style the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners */
}

/* Style the scrollbar track on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color on hover */
}

/* Style the scrollbar track background */
::-webkit-scrollbar-track {
  background: transparent; /* Set it to transparent */
}

/* Hide the scrollbar buttons (arrows) */
::-webkit-scrollbar-button {
  display: none;
}
